const mobileHeader = () => {
    const header = document.getElementById("header-mobile");
    const openButton = document.querySelector("[data-mobile-header-open-button]");
    const closeButton = document.querySelector("[data-mobile-header-close-button]");

    openButton.addEventListener("click", (e) => {
        e.preventDefault();
        header.classList.remove("dn");
        setTimeout(() => {
            header.classList.add("active");
        }, 1);
    });

    closeButton.addEventListener("click", (e) => {
        e.preventDefault();
        header.classList.remove("active");
        setTimeout(() => {
            header.classList.add("dn");
        }, 300);
    });
}

export {mobileHeader}
