import {languages} from "./main/languages";
import {anchor} from "./modules/anchor";
import {mobileHeader} from "./modules/mobileHeader";
import {productsModal} from "./modules/productsModal";
import {heroSlider} from "./main/heroSlider";
import {contactForm} from "./modules/contactForm";
import {successModal} from "./modules/successModal";
import {countryListOpener} from "./modules/countryListOpener";
import {toTopButton} from "./modules/toTopButton";
import {phoneForm} from "./modules/phoneForm";

//home anchor
const homeButton = document.getElementById("home-button");
const homeButtonFooter = document.getElementById("home-button-footer");
const homeAnchor = document.getElementById("home-anchor");
const homeButtonMobile = document.getElementById("home-button-mobile");
//company anchor
const companyButton = document.getElementById("company-button");
const companyButtonFooter = document.getElementById("company-button-footer");
const companyAnchor = document.getElementById("company-anchor");
const companyButtonMobile = document.getElementById("company-button-mobile");
//certifications anchor
const certificationsButton = document.getElementById("certifications-button");
const certificationsButtonFooter = document.getElementById("certifications-button-footer");
const certificationsAnchor = document.getElementById("certifications-anchor");
const certificationsButtonMobile = document.getElementById("certifications-button-mobile");
//contact anchor
const contactButton = document.getElementById("contact-button");
const contactButtonFooter = document.getElementById("contact-button-footer");
// const contactButtonCertifications = document.getElementById("contact-button-certifications");
const contactAnchor = document.getElementById("contact-anchor");
const contactButtonMobile = document.getElementById("contact-button-mobile");
//hello anchor
const helloButton = document.getElementById("hello-button");
const helloButtonMobile = document.getElementById("hello-button-mobile");
//product about anchor
const pAboutButton = document.getElementById("products-about-button");
const pAboutAnchor = document.getElementById("products-about-anchor");

//async loading for not important JS files
document.addEventListener("DOMContentLoaded", () => {
    languages();
    anchor(homeButton, homeAnchor, homeButtonMobile);
    anchor(homeButtonFooter, homeAnchor);
    anchor(companyButton, companyAnchor, companyButtonMobile);
    anchor(companyButtonFooter, companyAnchor);
    anchor(certificationsButton, certificationsAnchor, certificationsButtonMobile);
    anchor(certificationsButtonFooter, certificationsAnchor);
    anchor(contactButton, contactAnchor, contactButtonMobile);
    anchor(contactButtonFooter, contactAnchor);
    // anchor(contactButtonCertifications, contactAnchor);
    anchor(helloButton, contactAnchor, helloButtonMobile);
    anchor(pAboutButton, pAboutAnchor);
    mobileHeader();
    productsModal();
    heroSlider();
    contactForm();
    successModal();
    countryListOpener();
    toTopButton();
    phoneForm();
});
