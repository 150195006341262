const phoneForm = () => {
    let error;
    // const phoneValidation = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const forms = document.querySelectorAll("[data-phone-form]");

    const phoneInputValidation = (phone) => {
        // if (!phone.value.match(phoneValidation)) {
        if (phone.value.length < 9) {
            phone.value = "";
            phone.setAttribute("placeholder", "Invalid phone number");
            phone.parentElement.classList.remove("check");
            phone.parentElement.classList.add("error");
            error = true;
        }
        else {
            phone.parentElement.classList.remove("error");
            phone.parentElement.classList.add("check");
        }
    }

    //set default input styles and placeholder
    const inputFocusLogic = (input, phrase) => {
        input.addEventListener("focus", () => {
            input.setAttribute("placeholder", phrase);
            input.parentElement.classList.remove("error");
        });
    }

    //validate input value
    const inputBlurLogic = (input, validationLogic) => {
        input.addEventListener("blur", () => {
            validationLogic(input);
        });
    }

    for (const form of forms) {
        const submitBtnText = form.querySelector('#submit-btn-text');
        const submitBtnLoadingSvg = form.querySelector('#submit-btn-loading-svg');
        const submitBtnSuccessSvg = form.querySelector('#submit-btn-success-svg');
        const input = form.querySelector("[data-phone-form-input]");

        inputFocusLogic(input, "");
        inputBlurLogic(input, phoneInputValidation);

        //form submit logic
        form.addEventListener('submit', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            
            error = false;

            //validation logic
            phoneInputValidation(input);

            //exit from event and scroll to top of the form
            if (error) {
                return form.scrollIntoView({behavior: "smooth"});
            }
        
            //Creating FormData object
            const fData = new FormData(form);
            const fAction = form.getAttribute('action');

            // Display the key/value pairs
            for(const pair of fData.entries()) {
                console.log(pair[0]+ ': '+ pair[1]);
            }

            //change text to loading svg
            if (submitBtnSuccessSvg.classList.contains('dn')) {
                submitBtnText.classList.add('hidden');
                setTimeout(() => {
                    submitBtnText.classList.add('dn');
                    submitBtnLoadingSvg.classList.remove('dn');
                    setTimeout(() => {
                        submitBtnLoadingSvg.classList.remove('hidden');
                    }, 10);
                }, 500);
            }

            // Sending form to server
            const fetchResponse = await fetch(fAction, {
                method: 'POST',
                body: fData,
            });

            const result = await fetchResponse.json();

            //change loading svg to success svg
            submitBtnLoadingSvg.classList.add('hidden');
            setTimeout(() => {
                submitBtnLoadingSvg.classList.add('dn');
                submitBtnSuccessSvg.classList.remove('dn');
                setTimeout(() => {
                    submitBtnSuccessSvg.classList.remove('hidden');
                }, 10);
            }, 500);
                
            if (fetchResponse.ok) {
                console.log(result);
            } else {
                console.log(result);
            }
        });
    }
}

export {phoneForm}
