const contactForm = () => {
    let error;
    const emailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    // const phoneValidation = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const form = document.getElementById("contact-form");
    const name = document.getElementById("name");
    const phone = document.getElementById("phone");
    const email = document.getElementById("email");
    const submitBtnText = form.querySelector('#submit-btn-text');
    const submitBtnLoadingSvg = form.querySelector('#submit-btn-loading-svg');
    const submitBtnSuccessSvg = form.querySelector('#submit-btn-success-svg');
    const successModal = document.getElementById("success-modal");

    const nameInputValidation = () => {
        if (name.value.length < 1) {
            name.value = "";
            name.setAttribute("placeholder", "Name can't be empty");
            name.parentElement.classList.remove("check");
            name.parentElement.classList.add("error");
            error = true;
        }
        else {
            name.parentElement.classList.remove("error");
            name.parentElement.classList.add("check");
        }
    }

    const phoneInputValidation = () => {
        // if (!phone.value.match(phoneValidation)) {
        if (phone.value.length < 9) {
            phone.value = "";
            phone.setAttribute("placeholder", "Invalid phone number");
            phone.parentElement.classList.remove("check");
            phone.parentElement.classList.add("error");
            error = true;
        }
        else {
            phone.parentElement.classList.remove("error");
            phone.parentElement.classList.add("check");
        }
    }

    const emailInputValidation = () => {
        if (!email.value.match(emailValidation)) {
            email.value = "";
            email.setAttribute("placeholder", "Invalid email address");
            email.parentElement.classList.remove("check");
            email.parentElement.classList.add("error");
            error = true;
        }
        else {
            email.parentElement.classList.remove("error");
            email.parentElement.classList.add("check");
        }
    }

    //set default input styles and placeholder
    const inputFocusLogic = (input, phrase) => {
        input.addEventListener("focus", () => {
            input.setAttribute("placeholder", phrase);
            input.parentElement.classList.remove("error");
        });
    }

    //validate input value
    const inputBlurLogic = (input, validationLogic) => {
        input.addEventListener("blur", () => {
            validationLogic();
        });
    }

    inputFocusLogic(name, "");
    inputFocusLogic(phone, "");
    inputFocusLogic(email, "");
    // inputFocusLogic(name, "Full name");
    // inputFocusLogic(phone, "Your phone");
    // inputFocusLogic(email, "Enter your email");
    inputBlurLogic(name, nameInputValidation);
    inputBlurLogic(phone, phoneInputValidation);
    inputBlurLogic(email, emailInputValidation);

    //form submit logic
    form.addEventListener('submit', async (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        error = false;

        //validation logic
        nameInputValidation();
        phoneInputValidation();
        emailInputValidation();

        //exit from event and scroll to top of the form
        if (error) {
            return form.scrollIntoView({behavior: "smooth"});
        }
    
        //Creating FormData object
        const fData = new FormData(form);
        if (document.getElementById("country").value.length > 1) {
            fData.append('country', document.getElementById("country").value);
        }
        const fAction = form.getAttribute('action');

        // Display the key/value pairs
        for(const pair of fData.entries()) {
            console.log(pair[0]+ ': '+ pair[1]);
        }

        //change text to loading svg
        if (submitBtnSuccessSvg.classList.contains('dn')) {
            submitBtnText.classList.add('hidden');
            setTimeout(() => {
                submitBtnText.classList.add('dn');
                submitBtnLoadingSvg.classList.remove('dn');
                setTimeout(() => {
                    submitBtnLoadingSvg.classList.remove('hidden');
                }, 10);
            }, 500);
        }

        // Sending form to server
        const fetchResponse = await fetch(fAction, {
            method: 'POST',
            body: fData,
        });

        const result = await fetchResponse.json();

        //change loading svg to success svg
        submitBtnLoadingSvg.classList.add('hidden');
        setTimeout(() => {
            submitBtnLoadingSvg.classList.add('dn');
            submitBtnSuccessSvg.classList.remove('dn');
            setTimeout(() => {
                submitBtnSuccessSvg.classList.remove('hidden');
            }, 10);
        }, 500);
            
        if (fetchResponse.ok) {
            console.log(result);
            successModal.classList.remove("dn");
            name.value = "";
            phone.value = "";
            email.value = "";
            document.getElementById("country").value = "";
            for (const listItem of document.querySelector("#country").parentElement.querySelector("[data-country-list]").querySelectorAll("[data-list-item]")) {
                listItem.classList.remove("active");
            }
            document.getElementById("message").value = "";
            setTimeout(() => {
                submitBtnLoadingSvg.classList.add('hidden');
                submitBtnLoadingSvg.classList.add('dn');
                submitBtnSuccessSvg.classList.add('hidden');
                submitBtnSuccessSvg.classList.add('dn');
                submitBtnText.classList.remove('hidden');
                submitBtnText.classList.remove("dn");
            }, 1000);
        } else {
            console.log(result);
        }
    });
}

export {contactForm}
