const anchor = (button, element, mobileButton) => {
    const header = document.getElementById("header-mobile");
    
    button.addEventListener("click", (e) => {
        e.preventDefault();
        element.scrollIntoView({behavior: "smooth"});
    });

    if (mobileButton) {
        mobileButton.addEventListener("click", (e) => {
            e.preventDefault();
            header.classList.remove("active");
            setTimeout(() => {
                header.classList.add("dn");
            }, 300);
            element.scrollIntoView({behavior: "smooth"});
        });
    }
}

export {anchor}
