const productsModal = () => {
    const modal = document.getElementById("products-modal");
    const openButtons = document.querySelectorAll("[data-products-button='open']");
    const closeButtons = document.querySelectorAll("[data-products-button='close']");

    for (const openButton of openButtons) {
        openButton.addEventListener("click", (e) => {
            e.preventDefault();
            modal.classList.remove("dn");
            for (const openBtn of openButtons) {
                openBtn.classList.add("dn");
            }
            for (const closeBtn of closeButtons) {
                closeBtn.classList.remove("dn")
            }
        });
    }

    for (const closeButton of closeButtons) {
        closeButton.addEventListener("click", () => {
            modal.classList.add("dn");
            for (const openBtn of openButtons) {
                openBtn.classList.remove("dn");
            }
            for (const closeBtn of closeButtons) {
                closeBtn.classList.add("dn")
            }
        });
    }
}

export {productsModal}
