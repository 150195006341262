import Swiper from 'swiper/bundle';

const heroSlider = () => {

    //slider settings and logic
    const sliderSettings = {
        speed: 700,
        centeredSlides: false,
        loop: true,

        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    }

    const slider = new Swiper('#hero-slider', sliderSettings);

}

export {heroSlider}
