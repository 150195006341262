const toTopButton = () => {
    const button = document.getElementById("to-top-button");

    button.addEventListener("click", () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
}

export {toTopButton}
