const languages = () => {
    const buttons = document.querySelectorAll("[data-language-button]");

    const text = {
        eng: {
            header__link_home: "Home",
            header_link_company: "Company",
            header_link_certifications: "Certifications",
            header_link_delivery: "Delivery",
            header_link_contact: "Contact",
            header_link_products: "Products",
            header_menu: "Menu",
            header_menu_close: "Close",
            product_1_title: "Pine pellets A1",
            product_2_title: "Pine pellets A1",
            product_3_title: "Pellets from grain crops waste",
            product_4_title: "Pellets from grain crops waste",
            product_1_packaging: "Packing - bag 15 kg",
            product_2_packaging: "Packing - Run (1 ton)",
            product_3_packaging: "Packing - bag 15 kg",
            product_4_packaging: "Packing - Run (1 ton)",
            product_1_diameter: "Diameter - 6, 8 mm",
            product_2_diameter: "Diameter - 6, 8 mm",
            // product_3_diameter: "Diameter - 6, 8 mm",
            product_4_diameter: "Diameter - 6, 8 mm",
            product_1_length: "Length of pellets - 16.2 mm",
            product_2_length: "Length of pellets - 16.2 mm",
            product_3_length: "Length of pellets ±50 mm",
            product_4_length: "Length of pellets ±50 mm",
            product_1_ash: "Ash content - up to 0.7%",
            product_2_ash: "Ash content - up to 0.7%",
            product_3_ash: "Ash content in the dry state <8%",
            product_4_ash: "Ash content in the dry state <8%",
            product_1_water: "Moisture content - up to 9%",
            product_2_water: "Moisture content - up to 9%",
            product_3_water: "Mass fraction of moisture <10%",
            product_4_water: "Mass fraction of moisture <10%",
            product_3_nasip: "Bulk density, not less than 585 kg/m3",
            product_4_nasip: "Bulk density, not less than 585 kg/m3",
            product_1_calorie: "Calorific value - 800 kcal/kg",
            product_2_calorie: "Calorific value - 800 kcal/kg",
            product_3_calorie: "Heat of combustion 15-19 MJ/kg",
            product_4_calorie: "Heat of combustion 15-19 MJ/kg",
            product_3_fire: "Highest heat of combustion 4745 kcal/kg",
            product_4_fire: "Highest heat of combustion 4745 kcal/kg",
            product_3_fly: "Mass fraction of volatile substances, not less than 70%",
            product_4_fly: "Mass fraction of volatile substances, not less than 70%",
            product_1_certificate: "Certificate - ENplus A1",
            product_2_certificate: "Certificate - ENplus A1",
            product_3_certificate: "Sulfur content 0,05-0,2%",
            product_4_certificate: "Sulfur content 0,05-0,2%",
            hello_button: "Say Hello",
            hero_title: "Wood pellets from Rotrade LLC are natural wood products",
            phone_input_placeholder: "Your phone number",
            send_button: "Send",
            phone_form_description: "Write your phone number and we will call you back",
            company_title: "Company",
            company_description_title: "ROTRADE has been engaged in the production of quality pellets in Ukraine for more than 10 years",
            company_description: `
                We specialize in the production of fuel granules (pellets) of two fractions: 6 mm and 8 mm. As raw materials we use only coniferous wood (100% pine) and grain waste. We receive sawdust and wood shavings exclusively from sawmills in Ukraine.
                <br><br>
                For pellet delivery, we use proven trucks and disinfected warehouses for product storage.
                <br><br>
                We sell wood pellets of the highest quality to all EU countries. Our pellets have a confirmed standard with the ENplus certificate.
            `,
            certifications_title: "Certifications",
            certifications_description_title: "The products of ROTRADE LLC have passed certification according to the ENplus A1 standard",
            certifications_description: `
                ENplus is a special quality standard for wood fuel pellets (pellets), which provides an internationally recognized basis and guarantees compliance with industry standards.
                <br>
                ENplus A1 is the highest quality class of pellets that our company supplies to the European biomass fuel market.
                <br>
                ENplus certification meets high quality standards throughout the production chain: from production to storage and transportation to final sale.
                <br><br>
                The detailed characteristics of our pellets meet "EN" quality standards: diameter, length, bulk weight (density), calorific value, moisture, ash content, content of sulfur and other solid particles, mechanical properties of pellets (percentage of abrasion and dust). But they are constantly checked.
                <br><br>
                <span class="bold">The quality of our products is confirmed by certification.</span>
            `,
            download_pdf: "Download PDF",
            contact_us: "Contact us",
            products_title: "Products",
            products_modal_subtitle: "About Rotrade pellets",
            products_modal_offer: "We offer two types of pellets: from wood and grain waste",
            products_modal_type_1: "- pellets are suitable for all types of burners",
            products_modal_type_2: "- do not contain impurities (only pure raw materials without sand and bark)",
            products_modal_type_3: "- high heat transfer",
            products_description_title: "Wood pellets from ROTRADE LLC are natural wood products",
            products_description_subtitle: `
                We produce wood pellets and pellets from grain waste.
                <br>
                Thanks to the binding power of lignin, which is contained in wood, pellets are not only an ecological product without additives or chemical non-wood substances, but also a highly efficient fuel.
            `,
            products_description_text: `
                Since the wood is pressed, the calorific value of the pellets is twice that of the same amount of wood.
                <br>
                Straw and husk is a raw material that is perfect for the manufacture of fuel pellets, because it is environmentally friendly, easy to process and burns well. In addition, straw or husk of any plant crops is suitable for the manufacture of pellets.
                <br>
                The use of grain waste is an opportunity to preserve forest plantations. Straw, bran and husk are wastes that must be recycled or disposed of in any case.
                <br>
                Pellets from residues are practically CO2 neutral, because when burned, they release almost the same amount of carbon dioxide that was previously consumed by plants and, thanks to photosynthesis, was converted into oxygen. 
                <br>
                Our pellets are a top quality product with excellent characteristics in accordance with the requirements of the ENplus standard: debarked, without additives, high in calories and low in ash.
            `,
            products_description_title_2: "Pellets from ROTRADE LLC are your alternative to traditional fuel",
            // products_description_subtitle_2: "Quality A1 pine pellets for home and business. Packaging of 15kg and Big-bags. Ash 0.5%",
            products_anchor: "Grain waste pellets are virtually CO2-neutral, as they release almost the same amount of carbon dioxide that was previously consumed by plants and converted to oxygen through photosynthesis.",
            contact_title: "Contact",
            contact_subtitle: "To find out delivery conditions and prices, leave us a request. Our manager will contact you as soon as possible",
            form_title: "Form for sending",
            form_name_label: "Full name",
            form_phone_label: "Phone number",
            form_email_label: "Email address",
            form_country_label: "Country",
            form_country_input_placeholder: "Change country",
            form_message_label: "Your message",
            form_message_input_placeholder: "Write something...",
            success_modal_title: "Thank you for your order.",
            success_modal_text: "Our manager will contact you shortly to clarify the details.",
            company_address: "Chervonotkatska, 59A, 02094",
            company_city: "Kyiv"
        },

        deu: {
            header__link_home: "Home",
            header_link_company: "Firma",
            header_link_certifications: "Zertifizierungen",
            header_link_delivery: "Lieferung",
            header_link_contact: "Kontakt",
            header_link_products: "Produkte",
            header_menu: "Menu",
            header_menu_close: "Schließen",
            product_1_title: "Kiefernholzpellets A1",
            product_2_title: "Kiefernholzpellets A1",
            product_3_title: "Pellets aus Getreideabfällen",
            product_4_title: "Pellets aus Getreideabfällen",
            product_1_packaging: "Verpackung - Sack 15 kg",
            product_2_packaging: "Verpackung - Lauf (1 Tonne)",
            product_3_packaging: "Verpackung - Sack 15 kg",
            product_4_packaging: "Verpackung - Lauf (1 Tonne)",
            product_1_diameter: "Durchmesser - 6, 8 mm",
            product_2_diameter: "Durchmesser - 6, 8 mm",
            // product_3_diameter: "Durchmesser - 6, 8 mm",
            product_4_diameter: "Durchmesser - 6, 8 mm",
            product_1_length: "Länge der Kügelchen - 16,2 mm",
            product_2_length: "Länge der Kügelchen - 16,2 mm",
            product_3_length: "Länge der Kügelchen ±50 mm",
            product_4_length: "Länge der Kügelchen ±50 mm",
            product_1_ash: "Aschegehalt - bis zu 0,7%",
            product_2_ash: "Aschegehalt - bis zu 0,7%",
            product_3_ash: "Aschegehalt im Trockenzustand <8%",
            product_4_ash: "Aschegehalt im Trockenzustand <8%",
            product_1_water: "Feuchtigkeitsgehalt - bis zu 9%",
            product_2_water: "Feuchtigkeitsgehalt - bis zu 9%",
            product_3_water: "Massenanteil der Feuchtigkeit <10%",
            product_4_water: "Massenanteil der Feuchtigkeit <10%",
            product_3_nasip: "Schüttdichte, nicht weniger als 585 kg/m3",
            product_4_nasip: "Schüttdichte, nicht weniger als 585 kg/m3",
            product_1_calorie: "Brennwert - 800 kcal/kg",
            product_2_calorie: "Brennwert - 800 kcal/kg",
            product_3_calorie: "Verbrennungswärme 15-19 MJ/kg",
            product_4_calorie: "Verbrennungswärme 15-19 MJ/kg",
            product_3_fire: "Höchste Verbrennungswärme 4745 kcal/kg",
            product_4_fire: "Höchste Verbrennungswärme 4745 kcal/kg",
            product_3_fly: "Massenanteil an flüchtigen Stoffen, mindestens 70%",
            product_4_fly: "Massenanteil an flüchtigen Stoffen, mindestens 70%",
            product_1_certificate: "Zertifikat - ENplus A1",
            product_2_certificate: "Zertifikat - ENplus A1",
            product_3_certificate: "Schwefelgehalt 0,05-0,2%",
            product_4_certificate: "Schwefelgehalt 0,05-0,2%",
            hello_button: "Sag Hallo",
            hero_title: "Holzpellets von ROTRADE LLC sind natürliche Holzprodukte",
            phone_input_placeholder: "Deine Telefonnummer",
            send_button: "Senden",
            phone_form_description: "Schreiben Sie Ihre Telefonnummer und wir rufen Sie zurück",
            company_title: "Gesellschaft",
            company_description_title: "Das Unternehmen ROTRADE produziert seit mehr als 10 Jahren Qualitätspellets in der Ukraine",
            company_description: `
                Wir sind auf die Herstellung von Brennstoffpellets in zwei Größen spezialisiert: Durchmesser  6 mm und 8 mm. Als Rohstoffe verwenden wir ausschließlich Nadelholz (100% Kiefer) und Getreideabfälle. Wir beziehen Sägemehl und Holzspäne ausschließlich von Sägewerken in der Ukraine.
                <br><br>
                Für die Lieferung der Pellets verwenden wir bewährte Lastwagen und desinfizierte Lagerhallen für die Lagerung der Produkte.
                <br><br>
                Wir verkaufen Holzpellets von höchster Qualität in alle EU-Länder. Unsere Pellets haben einen bestätigten Standard mit ENplus Zertifikat.
            `,
            certifications_title: "Zertifikate",
            certifications_description_title: "Die Produkte von ROTRADE sind nach der Norm ENplus A1 zertifiziert",
            certifications_description: `
                ENplus ist eine spezielle Qualitätsnorm für Holzpellets, die einen international anerkannten Rahmen bietet und die Einhaltung von Industriestandards garantiert.
                <br>
                ENplus A1 ist die höchste Qualitätsklasse von Pellets, die unser Unternehmen auf dem europäischen Biomassebrennstoffmarkt anbietet.
                <br>
                Die ENplus - Zertifizierung erfüllt hohe Qualitätsstandards in der gesamten Produktionskette: von der Produktion über die Lagerung und den Transport bis hin zum Endverkauf.
                <br><br>
                Die detaillierten Eigenschaften unserer Pellets entsprechen den EN-Qualitätsnormen: Durchmesser, Länge, Schüttdichte, Heizwert, Feuchtigkeitsgehalt, Aschegehalt, Schwefel und andere feste Partikel, mechanische Eigenschaften der Pellets (Prozentsatz von Abrieb und Staub). Und ständig kontrolliert            
                <br><br>
                <span class="bold">Die Qualität unserer Produkte wird durch eine Zertifizierung bestätigt.</span>
            `,
            download_pdf: "PDF Herunterladen",
            contact_us: "Kontaktiere uns",
            products_title: "Produkte",
            products_modal_subtitle: "Über Rotrade-Pellets",
            products_modal_offer: "Wir bieten zwei Arten von Pellets an: aus Holz und Getreideabfällen",
            products_modal_type_1: "- keine Verunreinigungen enthalten (nur reine Rohstoffe ohne Sand und Rinde)",
            products_modal_type_2: "- hohe Wärmeübertragung",
            products_modal_type_3: "- Bild + Beschreibung (3 - Paket, Lauf, Palette)",
            products_description_title: "Holzpellets von ROTRADE sind natürliche Holzprodukte",
            products_description_subtitle: `
                Wir produzieren Holzpellets und Pellets aus Getreideabfällen    
                <br>
                Dank der Bindekraft des im Holz enthaltenen Lignins sind Pellets nicht nur ein ökologisches Produkt ohne Zusatzstoffe oder chemische Nichtholzsubstanzen, sondern auch ein hocheffizienter Brennstoff.
            `,
            products_description_text: `
                Da das Holz gepresst wird, ist der Heizwert der Pellets doppelt so hoch wie bei der gleichen Menge Holz.
                <br>
                Stroh und Spreu ist ein Rohstoff, der sich hervorragend für die Herstellung von Brennstoffpellets eignet, da er umweltfreundlich ist, sich leicht verarbeiten lässt und gut brennt. Darüber hinaus eignen sich Stroh oder Schalen jeglicher Pflanzenarten für die Herstellung von Pellets.
                <br>
                Die Verwendung von Getreideabfällen ist eine Möglichkeit, Waldplantagen zu erhalten. Stroh, Kleie und Schalen sind Abfälle, die auf jeden Fall verwertet oder beseitigt werden müssen.
                <br>
                Pellets aus Reststoffen sind praktisch CO2-neutral, da sie bei der Verbrennung fast die gleiche Menge an Kohlendioxid freisetzen, die zuvor von den Pflanzen verbraucht und dank der Photosynthese in Sauerstoff umgewandelt wurde. 
                <br>
                Unsere Pellets sind ein Spitzenprodukt mit hervorragenden Eigenschaften gemäß den Anforderungen der Norm ENplus: entrindet, ohne Zusatzstoffe, kalorienreich und aschearm.            
            `,
            products_description_title_2: "Pellets von ROTRADE sind Ihre Alternative zu herkömmlichen Brennstoffen",
            // products_description_subtitle_2: "A1-Kiefernholzpellets in bester Qualität für Haus und Gewerbe. Verpackungen von 15 kg und Big-Bags. Asche 0,5%",
            products_anchor: "Getreideabfallpellets sind praktisch CO2-neutral, da sie fast die gleiche Menge an Kohlendioxid freisetzen, die zuvor von Pflanzen verbraucht und durch Photosynthese in Sauerstoff umgewandelt wurde.",
            contact_title: "Kontakt",
            contact_subtitle: "Um Lieferbedingungen und Preise zu erfahren, hinterlassen Sie uns eine Anfrage. Unser Vertriebsmanager wird sich so schnell wie möglich mit Ihnen in Verbindung setzen",
            form_title: "Formular zum Versenden",
            form_name_label: "Name",
            form_phone_label: "Telefon",
            form_email_label: "Email",
            form_country_label: "Land",
            form_country_input_placeholder: "Land wählen",
            form_message_label: "Ihre Nachricht",
            form_message_input_placeholder: "Schreibe etwas...",
            success_modal_title: "Vielen Dank für Ihren Auftrag.",
            success_modal_text: "Unser Manager wird Sie in Kürze kontaktieren, um die Details zu klären.",
            company_address: "Chervonotkatska, 59A, 02094",
            company_city: "Kyiv"
        },

        ua: {
            header__link_home: "Головна",
            header_link_company: "Компанія",
            header_link_certifications: "Сертифікати",
            header_link_delivery: "Доставка",
            header_link_contact: "Контакт",
            header_link_products: "Продукти",
            header_menu: "Меню",
            header_menu_close: "Закрити",
            product_1_title: "Пелети соснові А1",
            product_2_title: "Пелети соснові А1",
            product_3_title: "Пелети із відходів зернових культур",
            product_4_title: "Пелети із відходів зернових культур",
            product_1_packaging: "Фасування - мішок 15 кг",
            product_2_packaging: "Фасування - Бег (1 тонна)",
            product_3_packaging: "Фасування - мішок 15 кг",
            product_4_packaging: "Фасування - Бег (1 тонна)",
            product_1_diameter: "Діаметр - 6, 8мм",
            product_2_diameter: "Діаметр - 6, 8мм",
            // product_3_diameter: "Діаметр - 8 мм",
            product_4_diameter: "Діаметр - 6, 8мм",
            product_1_length: "Довжина пелети - 16,2 мм",
            product_2_length: "Довжина пелети - 16,2 мм",
            product_3_length: "Довжина пелет ±50 мм",
            product_4_length: "Довжина пелет ±50 мм",
            product_1_ash: "Зольність - до 0,7%",
            product_2_ash: "Зольність - до 0,7%",
            product_3_ash: "Зольність у сухому стані <8%",
            product_4_ash: "Зольність у сухому стані <8%",
            product_1_water: "Вологість - до 9%",
            product_2_water: "Вологість - до 9%",
            product_3_water: "Масова частка вологи	<10%",
            product_4_water: "Масова частка вологи	<10%",
            product_3_nasip: "Насипна щільність, не менше	585 кг/м3",
            product_4_nasip: "Насипна щільність, не менше	585 кг/м3",
            product_1_calorie: "Теплотворність - 800 ккал/кг",
            product_2_calorie: "Теплотворність - 800 ккал/кг",
            product_3_calorie: "Теплота спалювання	15-19 МДж/кг",
            product_4_calorie: "Теплота спалювання	15-19 МДж/кг",
            product_3_fire: "Найвища теплота згоряння 4745 ккал/кг",
            product_4_fire: "Найвища теплота згоряння 4745 ккал/кг",
            product_3_fly: "Масова частка летючих речовин, що найменше 70%",
            product_4_fly: "Масова частка летючих речовин, що найменше 70%",
            product_1_certificate: "Сертифікат - ENplus A1",
            product_2_certificate: "Сертифікат - ENplus A1",
            product_3_certificate: "Вміст сірки	0,05-0,2%",
            product_4_certificate: "Вміст сірки	0,05-0,2%",
            hello_button: "Привітайся",
            hero_title: "Продукція ROTRADE LLC пройшла сертифікацію за стандартом ENplus A1",
            phone_input_placeholder: "Ваш номер телефону",
            send_button: "Надіслати",
            phone_form_description: "Напишіть свій номер телефону і ми вам передзвонимо",
            company_title: "Компанія",
            company_description_title: "Компанія ROTRADE займається виробництвом якісних пелет в Україні понад 10 років",
            company_description: `
                Ми спеціалізуємося на виготовленні паливної гранули(пелети) двох фракцій: 6мм и 8мм. У якості сировини ми використовуємо виключно хвойні породи деревини (100% сосна) та відходи зернових культур. Отримуємо тирсу та деревну стружку виключно з лісопильних заводів в Україні.
                <br><br>
                Для доставки пелет використовуємо перевірені вантажівки та знезаражені склади для зберігання продукції.
                <br><br>
                Продаємо деревні пелети найвищої якості в усі країни ЄС. Наші пелети мають підтверджений стандарт сертифікатом ENplus.
            `,
            certifications_title: "Сертифікати",
            certifications_description_title: "Продукція ROTRADE пройшла сертифікацію за стандартом ENplus A1",
            certifications_description: `
                ENplus - це спеціальний стандарт якості деревних паливних гранул (пелет), що забезпечує міжнародно визнану основу та гарантує дотримання галузевих стандартів.
                <br>
                ENplus A1 - це вищий клас якості пелет, який наша компанія постачає на європейський ринок палива з біомаси.
                <br>
                Сертифікація ENplus відповідає високим стандартам якості по всьому ланцюжку виробництва: від виробництва до зберігання та транспортування до кінцевої реалізаії.
                <br><br>
                Детальні характеристики наших пелет відповідають стандартам якості «EN»: діаметр, довжина, насипна маса (щільність), теплотворність, вологість, зольність, вміст сірки та інших твердих частинок, механічні властивості пелет (відсоток стирання та пилу). Та постійно перевіряються           
                <br><br>
                <span class="bold">Якість нашої продукції підтверджена сертифікацією. </span>
            `,
            download_pdf: "Завантажити PDF",
            contact_us: "Зв'яжіться з нами",
            products_title: "Продукти",
            products_modal_subtitle: "Про пелети Rotrade",
            products_modal_offer: "Пропонуємо пелети двох видів: з деревини та відходів зернових культур",
            products_modal_type_1: "- пелети підходять до всіх типів пальників",
            products_modal_type_2: "- не містять домішок (тільки чиста сировина без піску і кори)",
            products_modal_type_3: "- висока тепловіддача",
            products_description_title: "Пелети від ROTRADE - це продукція з натуральної сировини.",
            products_description_subtitle: `
                Виготовляємо деревні пелети та пелети з відходів зернових культур.    
                <br>
                Завдяки зв’язувальній здатності лігніну, який міститься в деревині, пелети є не просто екологічним продуктом без добавок чи хімічних недеревних речовин, а й високоефективним паливом.
            `,
            products_description_text: `
                Оскільки деревина пресована, теплотворна здатність пелет вдвічі вища, ніж у такої ж кількості деревини.
                <br>
                Солома та лузга - сировина, яка відмінно підходить для виготовлення паливних пелет, адже вона є екологічно чистою, легкою в плані переробки та відмінно горить. До того ж, для виготовлення пелетів підходить солома або лузга будь-яких рослинних культур.
                <br>
                Використання відходів зернових культур - це можливість зберегти лісові насадження. Солома, висівки та лузга - це відходи, які підлягають переробці або утилізації у будь-якому випадку.
                <br>
                Наші пелети - це продукт вищої якості з чудовими характеристиками відповідно до вимог стандарту EN plus: очищені від кори, без добавок, з високою калорійністю та низьким вмістом золи.
            `,
            products_description_title_2: "Пелети від ROTRADE — Ваша альтернатива традиційному паливу",
            // products_description_subtitle_2: "Якісні Пелети соснові А1 для дому та бізнесу. Фасування 15 кг та Біг-беги. Зола 0,5%",
            products_anchor: "Пелети з відходів зернових культур є практично CO2 нейтральними, оскільки при спалюванні виділяється майже та кількість  вуглекислого газу, яка до цього була спожита рослинами і, завдяки фотосинтезу, була перетворена на кисень.",
            contact_title: "Контакт",
            contact_subtitle: "Щоб дізнатися умови доставки та ціни залиште нам заявку. Наш менеджер якнайшвидше зв'яжеться з Вами",
            form_title: "Форма для відправки",
            form_name_label: "Ім'я",
            form_phone_label: "Телефон",
            form_email_label: "Пошта",
            form_country_label: "Країна",
            form_country_input_placeholder: "Виберіть країну",
            form_message_label: "Ваше повідомлення",
            form_message_input_placeholder: "Напишіть щось...",
            success_modal_title: "Дякуємо за ваше замовлення.",
            success_modal_text: "Наш менеджер зв'яжеться з Вами найближчим часом для уточнення деталей.",
            company_address: "Червоноткацька, 59 А, 02094",
            company_city: "Kиїв"
        }
    }

    const changeToEng = () => {
        //set texts to blocks
        for (const languageItem of document.querySelectorAll("[data-languages]")) {
            languageItem.innerHTML = text.eng[languageItem.getAttribute("data-languages")];
        }
        //set texts to placeholders
        for (const languageItem of document.querySelectorAll("[data-languages-placeholder]")) {
            languageItem.setAttribute("placeholder", text.eng[languageItem.getAttribute("data-languages-placeholder")]);
        }
        //set language value to all forms to send right email
        for (const languageInput of document.querySelectorAll("[data-form-language-input]")) {
            languageInput.value = "Eng";
        }
    }

    const changeToDeu = () => {
        //set texts to blocks
        for (const languageItem of document.querySelectorAll("[data-languages]")) {
            languageItem.innerHTML = text.deu[languageItem.getAttribute("data-languages")];
        }
        //set texts to placeholders
        for (const languageItem of document.querySelectorAll("[data-languages-placeholder]")) {
            languageItem.setAttribute("placeholder", text.deu[languageItem.getAttribute("data-languages-placeholder")]);
        }
        //set language value to all forms to send right email
        for (const languageInput of document.querySelectorAll("[data-form-language-input]")) {
            languageInput.value = "Deu";
        }
    }

    const changeToUa = () => {
        //set texts to blocks
        for (const languageItem of document.querySelectorAll("[data-languages]")) {
            languageItem.innerHTML = text.ua[languageItem.getAttribute("data-languages")];
        }
        //set texts to placeholders
        for (const languageItem of document.querySelectorAll("[data-languages-placeholder]")) {
            languageItem.setAttribute("placeholder", text.ua[languageItem.getAttribute("data-languages-placeholder")]);
        }
        //set language value to all forms to send right email
        for (const languageInput of document.querySelectorAll("[data-form-language-input]")) {
            languageInput.value = "Ua";
        }
    }

    //add default eng language to localstorage to know which language needs to load for whole site
    if (!localStorage.getItem("language")) {
        localStorage.setItem("language", "Eng");
        changeToEng();
    }

    //set language for site
    // if (localStorage.getItem("language") == "Eng") {
    //     changeToEng();
    // }
    if (localStorage.getItem("language") == "Deu") {
        changeToDeu();
    }
    else if (localStorage.getItem("language") == "Ua") {
        changeToUa();
    }

    //language buttons logic
    for (const button of buttons) {
        const list = button.parentElement.querySelector("[data-language-list]");
        const listItems = list.querySelectorAll("[data-language-list-item]");
        const icon = button.querySelector("[data-language-icon]");
        const name = button.querySelector("[data-language-name]");

        //set language for language button
        if (localStorage.getItem("language") == "Eng") {
            icon.setAttribute("src", "./img/header/eng-language.svg");
            name.innerHTML = "Eng";
        }
        else if (localStorage.getItem("language") == "Deu") {
            icon.setAttribute("src", "./img/header/D-A-CH_Flag.svg");
            name.innerHTML = "Deu";
        }
        else if (localStorage.getItem("language") == "Ua") {
            icon.setAttribute("src", "./img/header/ua-language.svg");
            name.innerHTML = "Укр";
        }

        //language button click logic (open or close list)
        button.addEventListener("click", () => {
            list.classList.toggle("dn");
        });
        
        //language list buttons click logic (change language for all site)
        for (let i = 0; i < listItems.length; i++) {
            listItems[i].addEventListener("click", () => {
                if (i == 0) {
                    icon.setAttribute("src", "./img/header/eng-language.svg");
                    name.innerHTML = "Eng";
                    localStorage.setItem("language", "Eng");
                    changeToEng();
                }
                else if (i == 1) {
                    icon.setAttribute("src", "./img/header/D-A-CH_Flag.svg");
                    name.innerHTML = "Deu";
                    localStorage.setItem("language", "Deu");
                    changeToDeu();
                }
                else if (i == 2) {
                    icon.setAttribute("src", "./img/header/ua-language.svg");
                    name.innerHTML = "Укр";
                    localStorage.setItem("language", "Ua");
                    changeToUa();
                }
                list.classList.add("dn");
            });
        }
    }
}

export {languages}
