import {Country}  from "country-state-city";

const countryListOpener = () => {
    const countryInput = document.getElementById("country");
    const countryButton = document.querySelector("[data-country-button]");
    const countryList = document.querySelector("[data-country-list]");
    const countryIcon = document.querySelector("[data-coutry-list-icon]");

    countryButton.addEventListener("click", () => {
        if (countryList.classList.contains("dn")) {
            for (const list of document.querySelectorAll("[data-list]")) {
                list.classList.remove("active");
                list.classList.add("dn");
            }
            countryIcon.classList.add("active");
            countryList.classList.remove("dn");
            setTimeout(() => {
                countryList.classList.add("active");
            }, 1);
        }
        else {
            for (const list of document.querySelectorAll("[data-list]")) {
                list.classList.remove("active");
                setTimeout(() => {
                    list.classList.add("dn");
                }, 300);
            }
            countryIcon.classList.remove("active");
        }
    });

    window.addEventListener("click", (e) => {
        if (!e.target.closest(".contact-form__block")) {
            if (!countryList.classList.contains("dn")) {
                countryIcon.classList.remove("active");
                countryList.classList.remove("active");
                setTimeout(() => {
                    countryList.classList.add("dn");
                }, 300);
            }
        }
    });

    const countryItems = Country.getAllCountries();
    for (const cntry of countryItems) {
        //add all countries to country list
        countryList.innerHTML += `<div class="list-item" data-list-item data-county-iso-code="${cntry.isoCode}">${cntry.name}</div>`;
    }

    const countryListItems = countryList.querySelectorAll("[data-list-item]")
    for (const cntry of countryListItems) {
        cntry.addEventListener("click", () => {
            //deactivate all list items
            for (const i of countryListItems) {
                i.classList.remove("active")
            }
            //activate clicked list item
            cntry.classList.add("active");
            //set list item value as an input value
            countryInput.value = cntry.innerHTML;
        });
    }
}

export {countryListOpener}
